export const LIST_PAGE_LIMIT = 15

export const LIST_TEMPLATES = Object.freeze({
  invoice: 'invoice',
  inTransit: 'inTransit',
  done: 'done',
})

export const GROUP_BY_ENUM = Object.freeze({
  endTime: 'endTime',
  deliveredAt: 'deliveredAt',
})
