import get from 'lodash/get'
import { ModelBase } from './ModelBase'
import { Identity } from './Identity'
import { Lot } from './Lot'
import { enums } from '@shelf.network/js-sdk'
import { Deposit } from './Deposit'
import { Winner } from 'Models/Winner'

export class Participation extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)
    const r11s = raw.relationships || {}

    this.createdAt = new Date(raw.createdAt)
    this.updatedAt = new Date(raw.updatedAt || raw.createdAt)
    this.bidLimit = this._strFiat(raw.bidLimit)
    this.autoBidLimit = this._strFiat(raw.autoBidLimit)
    this.currentBid = this._strFiat(raw.currentBid)
    this.previousBid = this._strFiat(raw.previousBid)
    this.isRequestedBuyNow = Boolean(raw.requestedBuyNow)
    this.currency = this._str(raw.currency)
    this.state = this._str(raw.state)

    const identity = ModelBase.modelFromCache(Identity, r11s.identity, cache)
    this.fullName = identity.fullName
    this.email = identity.email
    this.phone = identity.phone
    this.fullName = identity.fullName
    this.avatarLink = identity.avatarLink
    this.accountId = identity.id
    this.platformId = identity.platformId

    this.leadId = get(r11s, 'lead.id', '')

    this.lot = ModelBase.modelFromCache(Lot, r11s.lot, cache)
    this.lotId = this.lot.id

    const rawDeposits = r11s.deposits || []
    const deposits = rawDeposits.map(deposit => {
      return ModelBase.modelFromCache(Deposit, deposit, cache)
    })
    this.depositIds = deposits.map(el => String(el.id))
    this.depositAmount =
      this._strFiat(deposits.reduce((acc, cur) => acc + Number(cur.amount), 0))
    this.depositCurrency = this._str((deposits[0] || {}).currency)

    // HACK: awful way to detect if we have an Identity, visible to current user
    this.isControllable = Boolean(this.email)
    this.isForcedToTop = Boolean(raw.isForcedToTop)

    this.broker = ModelBase.modelFromCache(
      Identity,
      get(r11s, 'brokers[0]'),
      cache
    )

    this.winner = ModelBase.modelFromCache(Winner, r11s.winner, cache)
  }

  get offer () {
    return this.isRequestedBuyNow
      ? this.lot.buyNowPrice
      : this.currentBid
  }

  static get statesEnum () {
    return {
      depositPending: enums.participantStates.depositPending,
      pending: enums.participantStates.pending,
      rejected: enums.participantStates.rejected,
      winner: enums.participantStates.winner,
      buyNowWinner: enums.participantStates.buyNowWinner,
      lost: enums.participantStates.lost,
      dealCanceled: enums.participantStates.dealCanceled,
    }
  }
}
