import { wonMutations } from './types'
import Vue from 'vue'
import get from 'lodash/get'

export default {
  [wonMutations.SET_PARTICIPATIONS] (state, pl) {
    state.participations = pl
  },
  [wonMutations.PUSH_PARTICIPATIONS] (state, pl) {
    state.participations = state.participations.concat((pl))
  },
  [wonMutations.SET_FETCH_NEXT] (state, pl) {
    state.fetchNext = pl
  },
  [wonMutations.SET_TOTAL_COUNT] (state, pl) {
    state.totalCount = pl
  },
  [wonMutations.SET_IS_LOADING] (state, pl) {
    state.isLoading = pl
  },
  [wonMutations.SET_IS_NEXT_LOADING] (state, pl) {
    state.isNextLoading = pl
  },
  [wonMutations.SET_INVOICE] (state, pl) {
    Vue.set(state.invoices, pl.id, pl)
  },
  [wonMutations.SET_WINNER_STATE] (state, { winnerId, state: winnerState }) {
    setWinnerField(state, winnerId, 'state', winnerState)
  },
  [wonMutations.SET_WINNER_INVOICE_ID] (state, { winnerId, invoiceId }) {
    setWinnerField(state, winnerId, 'invoiceId', invoiceId)
  },
  [wonMutations.SET_WINNER_TRANSPORTATION_PRICE] (state, { winnerId, price }) {
    setWinnerField(state, winnerId, 'transportationPrice', price)
  },
  [wonMutations.SET_WINNER_TRANSPORTATION_PRICE_CURRENCY] (state, pl) {
    const { winnerId, currency } = pl
    setWinnerField(state, winnerId, 'transportationPriceCurrency', currency)
  },
  [wonMutations.REMOVE_PARTICIPATION] (state, participationId) {
    const index = state.participations
      .findIndex(el => el.id === participationId)
    Vue.delete(state.participations, index)
  },
  [wonMutations.SET_PARTICIPATION_BID] (_, { participation, bid }) {
    Vue.set(participation, 'currentBid', Number(bid))
  },
}

function setWinnerField (state, winnerId, field, value) {
  const winner = getWinnerById(state.participations, winnerId)
  if (!winner) {
    return
  }

  Vue.set(winner, field, value)
}

function getWinnerById (participations, winnerId) {
  for (const p of participations) {
    const winner = get(p, 'relationships.winner')

    if (winner && winner.id === winnerId) {
      return winner
    }
  }
}
