export function genWonState () {
  return {
    participations: [],
    fetchNext: null,
    totalCount: 0,
    isLoading: false,
    isNextLoading: false,
    invoices: {},
  }
}

export default genWonState
