import { wonActions, wonGetters, wonMutations } from './types'
import { userGetters } from 'Store/entities/User/types'
import { rootGet } from 'Store/helpers/rootHelpers'

import { LIST_PAGE_LIMIT } from 'Components/Won/constants'
import { Winner } from 'Models/Winner'
import { sdk } from 'Services/shelfNetworkSdk'

import get from 'lodash/get'
import { LoneSdkCall } from 'Utils/LoneSdkCall'
import { getISODate } from 'Utils/dateHelpers'
import { showError } from 'Utils/notifications'
import { i18n } from 'I18n/instance'

const loneSdkCall = new LoneSdkCall()

export default {
  async [wonActions.LOAD_PARTICIPATIONS] ({ commit, dispatch }, payload = {}) {
    commit(wonMutations.SET_IS_LOADING, true)

    try {
      const query = ensureRequiredQueryParams(payload)
      const response = await loneSdkCall.takeLatest(
        sdk.backOffice.v2.getParticipationsWon(query)
      )

      commit(wonMutations.SET_PARTICIPATIONS, response.data)
      commit(wonMutations.SET_FETCH_NEXT, response.fetchNext)
      commit(wonMutations.SET_TOTAL_COUNT, response.meta.totalCount)

      await dispatch(wonActions.LOAD_INVOICES, response.data)
    } catch (err) {
      showError(i18n.t('TOASTS.WON_LIST_LOAD_FAILED'))
      console.error('wonActions.LOAD_PARTICIPATIONS', err)
    }

    commit(wonMutations.SET_IS_LOADING, false)
  },

  async [wonActions.LOAD_NEXT_PARTICIPATIONS] ({ dispatch, getters, commit }) {
    const fetchNext = getters[wonGetters.FETCH_NEXT]
    if (!fetchNext) return

    commit(wonMutations.SET_IS_NEXT_LOADING, true)

    const { data, fetchNext: newFetchNext } = await fetchNext()
    commit(wonMutations.PUSH_PARTICIPATIONS, data)
    commit(wonMutations.SET_FETCH_NEXT, newFetchNext)

    await dispatch(wonActions.LOAD_INVOICES, data)

    commit(wonMutations.SET_IS_NEXT_LOADING, false)
  },

  async [wonActions.LOAD_INVOICES] ({ commit }, participations) {
    const invoiceIds = participations
      .map(p => get(p, 'relationships.winner.invoiceId'))
      .filter(p => p)

    const { data: invoices } = await sdk.files.getPage({
      in: invoiceIds.join(','),
      'page.size': LIST_PAGE_LIMIT,
    })

    invoices.forEach(i => commit(wonMutations.SET_INVOICE, i))
  },

  async [wonActions.UPDATE_WINNER_TRANSPORTATION_PRICE] ({ commit }, pl) {
    const { participation, price, currency } = pl
    const winner = participation.winner

    const attributes = composeWinnerDetails(winner, {
      transportationPrice: price,
      transportationPriceCurrency: currency,
    })
    await sdk.buyingPower.updateWinnerDetails({
      lotId: participation.lotId,
      accountId: participation.accountId,
      attributes,
    })

    commit(wonMutations.SET_WINNER_TRANSPORTATION_PRICE, {
      winnerId: winner.id,
      price
    })
    commit(wonMutations.SET_WINNER_TRANSPORTATION_PRICE_CURRENCY, {
      winnerId: winner.id,
      currency
    })
  },

  async [wonActions.UPDATE_WINNER_DETAILS] ({ commit }, pl) {
    const { participation, state, ...params } = pl
    const winner = participation.winner

    const attributes = composeWinnerDetails(winner, { state, ...params })
    await sdk.buyingPower.updateWinnerDetails({
      lotId: participation.lotId,
      accountId: participation.accountId,
      attributes,
    })

    commit(wonMutations.SET_WINNER_STATE, {
      winnerId: winner.id,
      state
    })
    commit(wonMutations.REMOVE_PARTICIPATION, participation.id)
  },

  async [wonActions.UPDATE_PARTICIPATION_BID] ({ commit }, payload = {}) {
    const { bid, participation } = payload

    await sdk.buyingPower.participants.setBid({
      lotId: participation.lot.id,
      accountId: participation.accountId,
      amount: String(bid)
    })

    commit(wonMutations.SET_PARTICIPATION_BID, { bid, participation })
  },

  async [wonActions.ADD_WINNER_INVOICE] ({ rootGetters, commit }, pl) {
    const { file, participation } = pl
    const winner = participation.winner
    const adminAccountId = rootGetters[`entities/user/${userGetters.ACCOUNT_ID}`]

    const { data: invoice } = await sdk.files.upload(file, {
      isPublic: false,
      owners: [winner.accountId, adminAccountId]
    })

    const attributes = composeWinnerDetails(winner, {
      invoiceId: invoice.id,
      invoiceCreatedAt: invoice.createdAt,
    })
    await sdk.buyingPower.updateWinnerDetails({
      lotId: participation.lotId,
      accountId: participation.accountId,
      attributes,
    })

    commit(wonMutations.SET_INVOICE, invoice)
    commit(wonMutations.SET_WINNER_INVOICE_ID, {
      invoiceId: invoice.id,
      winnerId: winner.id
    })
  },

  async [wonActions.REMOVE_WINNER_INVOICE] ({ commit }, participation) {
    const winner = participation.winner
    const attributes = composeWinnerDetails(winner, { invoiceId: '' })
    await sdk.buyingPower.updateWinnerDetails({
      lotId: participation.lotId,
      accountId: participation.accountId,
      attributes,
    })

    commit(wonMutations.SET_WINNER_INVOICE_ID, {
      winnerId: winner.id,
      invoiceId: ''
    })
  },
}

function ensureRequiredQueryParams (payload) {
  const query = Object.assign({}, payload)
  query.filter = Object.assign({}, payload.filter)

  query.include = [
    'lots',
    'winners',
    'brokers',
    'deposits',
    'identities',
    'brokers.basics',
    'identities.basics',
  ].join(',')

  if (rootGet(`entities/user/${userGetters.IS_BROKER}`)) {
    query.filter.broker = query.filter.broker || rootGet(`entities/user/${userGetters.ACCOUNT_ID}`)
  } else {
    query.filter.platforms = query.filter.platforms || rootGet(`entities/user/${userGetters.PLATFORM_ID}`)
  }

  return query
}

function composeWinnerDetails (winner, attributes) {
  return {
    invoiceId: winner.invoiceId,
    invoiceCreatedAt: getISODate(winner.invoiceCreatedAt),
    containerId: winner.containerId,
    containerLink: winner.containerLink,
    fee: winner.fee,
    feeCurrency: winner.feeCurrency,
    transportationPrice: winner.transportationPrice,
    transportationPriceCurrency: winner.transportationPriceCurrency,
    state: winner.state || Winner.statesEnum.waitingForInvoice,
    deliveredAt: getISODate(winner.deliveredAt),
    ...attributes
  }
}
