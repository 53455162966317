import { wonGetters } from './types'
import { Participation } from 'Models/Participation'

export default {
  [wonGetters.PARTICIPATIONS]: state => state
    .participations
    .map(p => new Participation(p)),
  [wonGetters.TOTAL_COUNT]: state => state.totalCount,
  [wonGetters.FETCH_NEXT]: state => state.fetchNext,
  [wonGetters.IS_LOADING]: state => state.isLoading,
  [wonGetters.IS_NEXT_LOADING]: state => state.isNextLoading,
  [wonGetters.INVOICES]: state => state.invoices,
}
