export const wonActions = {
  LOAD_PARTICIPATIONS: 'LOAD_PARTICIPATIONS',
  LOAD_NEXT_PARTICIPATIONS: 'LOAD_NEXT_PARTICIPATIONS',
  LOAD_INVOICES: 'LOAD_INVOICES',
  UPDATE_WINNER_TRANSPORTATION_PRICE: 'UPDATE_WINNER_TRANSPORTATION_PRICE',
  UPDATE_WINNER_DETAILS: 'UPDATE_WINNER_DETAILS',
  ADD_WINNER_INVOICE: 'ADD_WINNER_INVOICE',
  REMOVE_WINNER_INVOICE: 'REMOVE_WINNER_INVOICE',
  UPDATE_PARTICIPATION_BID: 'UPDATE_PARTICIPATION_BID',
}

export const wonMutations = {
  SET_PARTICIPATIONS: 'SET_PARTICIPATIONS',
  PUSH_PARTICIPATIONS: 'PUSH_PARTICIPATIONS',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_FETCH_NEXT: 'SET_FETCH_NEXT',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_INVOICE: 'SET_INVOICE',
  SET_PARTICIPATION_BID: 'SET_PARTICIPATION_BID',
  SET_WINNER_INVOICE_ID: 'SET_WINNER_INVOICE_ID',
  SET_WINNER_STATE: 'SET_WINNER_STATE',
  SET_WINNER_TRANSPORTATION_PRICE: 'SET_WINNER_TRANSPORTATION_PRICE',
  SET_WINNER_TRANSPORTATION_PRICE_CURRENCY: 'SET_WINNER_TRANSPORTATION_PRICE_CURRENCY',
  REMOVE_PARTICIPATION: 'REMOVE_PARTICIPATION',
}

export const wonGetters = {
  PARTICIPATIONS: 'PARTICIPATIONS',
  TOTAL_COUNT: 'TOTAL_COUNT',
  FETCH_NEXT: 'FETCH_NEXT',
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  INVOICES: 'INVOICES',
}
