import { ModelBase } from 'Models/ModelBase'
import { enums } from '@shelf.network/js-sdk'
import { config } from '@/config'

export class Winner extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.id = this._str(raw.id)
    this.accountId = this._str(raw.accountAddress)
    this.fee = this._strFiat(raw.fee)
    this.feeCurrency = this._str(raw.feeCurrency)
    this.invoiceId = this._str(raw.invoiceId)
    this.invoiceCreatedAt = this._date(raw.invoiceCreatedAt).getTime()
    this.containerId = this._str(raw.containerId)
    this.containerLink = this._str(raw.containerLink)
    this.state = this._str(raw.state)
    this.transportationPrice = this._strFiat(raw.transportationPrice)

    // TODO: some currencies are not present for some reason:
    // TODO: shouldn't it be lot currency instead of cfg.DEFAULT?
    this.transportationPriceCurrency =
      this._str(raw.transportationPriceCurrency) || config.DEFAULT_CURRENCY

    this.deliveredAt = this._date(raw.deliveredAt).getTime()
  }

  static get statesEnum () {
    return {
      unknown: enums.winnerStates.unknown,
      inTransit: enums.winnerStates.inTransit,
      dealCanceled: enums.winnerStates.dealCanceled,
      dealCompleted: enums.winnerStates.dealCompleted,
      waitingForInvoice: enums.winnerStates.waitingForInvoice,
      waitingForPayment: enums.winnerStates.waitingForPayment,
    }
  }
}
